<template>
  <div class="MemoView">
    <transition :name="transitionName">
      <MemoCard
        v-if="memoId"
        :key="memoId"
        class="card"
        :memo-id="memoId"
        :no-games="noGames"
        :start-with-story-mode="startWithStoryMode"
        :indicate-next="progress < 1"
        :activity-name="activityName" />
      <MemoGamesCard
        v-else
        :activity-name="activityName"
        :step-id="stepId"
        class="card" />
    </transition>
  </div>
</template>

<script>
import MemoCard from '../components/MemoCard';
import MemoGamesCard from '../components/MemoGamesCard';
import MemoOnboardingPopup from '@/modules/course/components/MemoOnboardingPopup';

export default {
    name: 'MemoView',
    components: { MemoCard, MemoGamesCard },
    props: {
        stepId: {
            type: String
        },
        activityName: {
            type: String
        },
        memoId: {
            type: String
        }
    },
    data() {
        return {
            transitionName: ''
        };
    },
    computed: {
        step() {
            return this.$store.state.moduleCourse.steps[this.stepId];
        },
        memoIds() {
            return this.step[this.activityName].memoIds;
        },
        isMultimemo() {
            return this.memoIds.length > 1;
        },
        progress() {
            return this.step[this.activityName].progress;
        },
        noGames() {
            return this.memoIds.length > 1;
        },
        startWithStoryMode() {
            return (this.activityName === 'memorize2' && !!this.step.memorize) || this.progress === 1;
        }
    },
    watch: {
        memoId(newMemoId, oldMemoId) {
            const newIndex = newMemoId ? this.memoIds.indexOf(newMemoId) : this.memoIds.length;
            const oldIndex = oldMemoId ? this.memoIds.indexOf(oldMemoId) : this.memoIds.length;
            this.transitionName = newIndex < oldIndex ? 'slideRight' : 'slideLeft';
        }
    },
    mounted() {
        if (!this.$store.state.moduleApp.memolife.didShowMemoOnboardingPopup) {
            this.showOnboardingPopup();
        }
    },
    methods: {
        showOnboardingPopup() {
            this.$modal
                .open({
                    component: MemoOnboardingPopup,
                    target: 'app-modal'
                })
                .waitDone()
                .then(message => {
                    this.$store.dispatch('moduleApp/markMemoOnboardingAsShown');
                });
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoView {
    padding: 2em;
    color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}

.MemoCard {
    width: 40em;
    max-width: calc(100% - 2em);

    max-height: calc(100% - 2em);
    position: absolute;
}

.MemoGamesCard {
    position: absolute;
    max-height: calc(100% - 2em);
}

.card {
    $transitionDuration: 0.5s;
    $scale: 0.8;
    $distance: 100%;
    &.slideLeft-enter-active,
    &.slideLeft-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideLeft-enter {
        transform: translate3d(calc(#{$distance} + 3em), 0, 0) scale($scale);
        opacity: 0;
    }
    &.slideLeft-leave-to {
        transform: translate3d(calc(-#{$distance} - 3em), 0, 0) scale($scale);
        opacity: 0;
    }

    &.slideRight-enter-active,
    &.slideRight-leave-active {
        transition: transform $transitionDuration ease-out, opacity $transitionDuration;
    }
    &.slideRight-enter {
        transform: translate3d(calc(-#{$distance} - 3em), 0, 0) scale($scale);
        opacity: 0;
    }
    &.slideRight-leave-to {
        transform: translate3d(calc(#{$distance} + 3em), 0, 0) scale($scale);
        opacity: 0;
    }
}
</style>
