<template>
  <div class="FantasyStandard">
    <template v-if="mnemonicIndex === -1">
      <div
        ref="phaseIntro"
        class="PhaseIntro">
        <div class="PhaseIntro__icon">
          <svgicon
            class="svg-icon"
            :icon="{ STORY: 'memo-story-2', IMAGE: 'memo-image-2' }[fantasyMode]" />
        </div>
        <div class="PhaseIntro__title">
          {{ $t(`${fantasyMode}.intro.title`) }}
        </div>
        <div class="PhaseIntro__text">
          {{ $t(`${fantasyMode}.intro.text`) }}
        </div>
      </div>
      <div class="spacer" />
    </template>
    <template v-else-if="fantasyMode === 'IMAGE'">
      <FantasyMnemonicDetail
        :key="mnemonicIndex"
        ref="fantasyMnemonicDetail"
        :mnemonic="currentMnemonic"
        :fantasy-text="text"
        @click.native="$emit('update:fantasy-mode', 'STORY')" />
      <div
        class="spacer"
        :class="{ hide: mnemonicIndex === -1 || fantasyMode === 'STORY' }" />
    </template>

    <FantasyMnemonicImages
      v-else
      ref="imageRow"
      :xclass="{ hide: mnemonicIndex === -1 || fantasyMode === 'IMAGE' }"
      :mnemonics="mnemonics"
      :mnemonic-index="mnemonicIndex"
      @update:mnemonic-index="onClickMnemonicImage" />
    <div
      ref="scrollContainer"
      class="textContainer"
      :class="{ hide: mnemonicIndex === -1 || fantasyMode === 'IMAGE' }">
      <div ref="content">
        <div class="textContainerTopPadding" />
        <TextWithHighlight
          class="text"
          :text="text"
          :range="textHighlight"
          theme="dark" />
        <div class="textContainerBottomPadding" />
      </div>
    </div>

    <div
      ref="imageModeMeasure"
      class="measureContainer">
      <FantasyMnemonicDetail measure />
    </div>
    <div
      ref="storyModeMeasure"
      class="measureContainer">
      <FantasyMnemonicImages
        ref="imageRowMeasure"
        :mnemonics="[]"
        :mnemonic-index="-1"
        measure />
      <div class="textContainer">
        <div class="textContainerTopPadding" />
        <TextWithHighlight
          class="text"
          :text="text" />
        <div class="textContainerBottomPadding" />
      </div>
    </div>
  </div>
</template>

<translations>
  showFantasy: 'Show Fantasy'
  showFantasy_no: 'Vis fantasi'
  IMAGE:
    intro:
      title: 'Link the keywords to images'
      title_no: 'Link nøkkelordene til bilder'
      text: 'Memory techniques let you unleash your brain’s super powers. The first step is to link the summary to images.'
      text_no: 'Husketeknikker lar deg slippe løs hjernens superkrefter med visuelle fantasier. Det første steget er å koble sammendraget til bilder.'

  STORY:
    intro:
      title: 'Link the images to a story'
      title_no: 'Link bildene til en historie'
      text: 'When you link the images to a fun story you’ll remember the summary and simultaneously train your brain to learn faster.'
      text_no: 'Når du kobler bildene til en morsom historie vil du huske sammendraget og samtidig trene hjernen til å lære raskere.'
</translations>

<script>
import ResizeObserver from 'resize-observer-polyfill';
import scrollIntoView from './scrollIntoView';

import FantasyMnemonicImages from './FantasyMnemonicImages';
import FantasyMnemonicDetail from './FantasyMnemonicDetail';
import TextWithHighlight from './TextWithHighlight';

export default {
    components: { FantasyMnemonicImages, FantasyMnemonicDetail, TextWithHighlight },
    props: {
        text: {
            type: String,
            required: true
        },
        mnemonics: {
            type: Array,
            required: true
        },
        mnemonicIndex: {
            type: Number,
            default: 0
        },
        fantasyMode: {
            type: String,
            default: 'IMAGE'
        }
    },
    computed: {
        currentMnemonic() {
            return this.mnemonics[this.mnemonicIndex] || null;
        },
        textHighlight() {
            if (!this.currentMnemonic) {
                return null;
            }
            // NOTE: We only have one marking per mnemonic:
            let ref = this.currentMnemonic.refs[0];
            if (!ref) {
                console.warn('missing ref for fantasy', this.currentMnemonic); // eslint-disable-line no-console
                return null;
            }
            return {
                start: ref.offset,
                length: ref.length
            };
        }
    },
    created() {
        this.active = false;
        this.observer = new ResizeObserver(this.handleContentResize);
    },
    mounted() {
        this.ignoreNextContentResize = true;
        this.observer.observe(this.$refs.storyModeMeasure);
        this.active = true;
    },
    activated() {
        if (!this.active) {
            this.ignoreNextContentResize = true;
            this.observer.observe(this.$refs.storyModeMeasure);
        }
        this.active = true;
    },
    deactivated() {
        this.observer.unobserve(this.$refs.storyModeMeasure);
        this.active = false;
    },
    beforeDestroy() {
        this.observer.unobserve(this.$refs.storyModeMeasure);
    },
    methods: {
        onClickMnemonicImage(index) {
            if (this.mnemonicIndex === index) {
                this.$emit('update:fantasy-mode', this.fantasyMode === 'IMAGE' ? 'STORY' : 'IMAGE');
            } else {
                this.$emit('update:mnemonic-index', index);
            }
        },
        handleContentResize(entries) {
            if (this.ignoreNextContentResize) {
                this.ignoreNextContentResize = false;
            } else if (this.active) {
                this.$emit('layout-changed');
            }
        },
        getReservedHeight() {
            return Math.max(this.$refs.imageModeMeasure.clientHeight, this.$refs.storyModeMeasure.clientHeight);
        },
        emitLayoutHint() {
            return this.getLayoutHint();
        },

        // Public method

        getLayoutHint() {
            const layoutHint = {
                reservedHeight: this.getReservedHeight()
            };
            if (this.mnemonicIndex === -1) {
                layoutHint.requestedHeight = this.$refs.phaseIntro.clientHeight;
            } else if (this.fantasyMode === 'IMAGE') {
                layoutHint.requestedHeight = this.$refs.fantasyMnemonicDetail.$el.clientHeight;
            } else {
                layoutHint.requestedHeight = this.$refs.imageRow.$el.clientHeight + this.$refs.content.clientHeight;
            }
            return layoutHint;
        },

        updateScroll() {
            scrollIntoView(this.$el.querySelector('.text .mark'), this.$refs.scrollContainer);
        }
    }
};
</script>

<style lang="scss" scoped>
@import './_variables.scss';

.FantasyStandard {
    position: relative;
    background-color: $memo-fantasyBackground;
    background-color: var(--memo-fantasyBackground, $memo-fantasyBackground);
    color: white;

    display: flex;
    flex-direction: column;
}
.FantasyMnemonicImages {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    flex-shrink: 0;
}
.FantasyMnemonicDetail {
    flex-shrink: 0;
}

.measureContainer {
    position: absolute;
    visibility: hidden;
    width: 100%;
}

.spacer {
    flex: 1;
}

.FantasyMnemonicImages,
.textContainer {
    &.hide {
        visibility: hidden;
        position: absolute;
        width: 100%;
    }
}

.textContainer {
    padding: 0 $memo-sidePadding;
    padding: 0 var(--memo-sidePadding, $memo-sidePadding);
    flex: 1;
    overflow-y: auto;

    line-height: 1.5em;

    /deep/ .mark {
        color: $memo-fantasyHighlightColor;
        color: var(--memo-fantasyHighlightColor, $memo-fantasyHighlightColor);
        font-weight: bold;
    }
}
.textContainerBottomPadding {
    height: 1em;
}

.PhaseIntro {
    text-align: center;
    padding: 1.5em 1.5em;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
}
.PhaseIntro__icon {
    margin-bottom: 1em;
    .svg-icon {
        $size: 2em;
        width: $size;
        height: $size;
        fill: white;
    }
}
.PhaseIntro__title {
    color: rgba(white, 0.9);
}
.PhaseIntro__text {
    font-weight: 300;
    line-height: 1.5em;
    color: rgba(white, 0.7);
    margin-top: 1em;
    max-width: 26em;
}
</style>
