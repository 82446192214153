import { TimelineLite } from 'gsap';

export default function scrollIntoView(elem, scrollContainer) {
    if (elem) {
        const { top: markElemTop, bottom: markElemBottom } = elem.getBoundingClientRect();
        const { top: containerTop, bottom: containerBottom } = scrollContainer.getBoundingClientRect();

        let scrollTopTarget = 0;
        let shouldScroll = false;
        if (markElemTop <= containerTop) {
            scrollTopTarget = scrollContainer.scrollTop - containerTop + markElemTop - 20;
            shouldScroll = true;
        } else if (markElemBottom >= containerBottom) {
            scrollTopTarget = scrollContainer.scrollTop + markElemBottom - containerBottom + 20;
            shouldScroll = true;
        }

        if (shouldScroll) {
            let tl = new TimelineLite({});

            tl.fromTo(
                scrollContainer,
                0.3,
                {
                    scrollTop: scrollContainer.scrollTop
                },
                {
                    scrollTop: scrollTopTarget
                },
                0
            );
        }
    }
}
