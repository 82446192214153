<template>
  <MemoSmall
    v-if="memo.type === 'small'"
    class="MemoCard"
    :fact="memo.fact"
    :fantasy="memo.fantasies[0]" />
  <CardStandard
    v-else
    :fact="memo.fact"
    :fantasy="memo.fantasies[0]"
    :initial-mnemonic-index="initialMnemonicIndex"
    :initial-fantasy-mode="initialFantasyMode"
    :indicate-next="indicateNext"
    :games="games"
    :theme="theme"
    class="MemoCard"
    @request-game="gotoGame" />
</template>

<script>
// import Color from 'color'
import CardStandard from '@/modules/memo/CardStandard';
import MemoSmall from '@/modules/memo/MemoSmall';

export default {
    components: { CardStandard, MemoSmall },
    props: {
        memoId: {
            type: String,
            required: true
        },
        noGames: {
            type: Boolean,
            default: false
        },
        startWithStoryMode: {
            type: Boolean,
            default: false
        },
        indicateNext: {
            type: Boolean,
            default: false
        },
        activityName: {
            type: String
        }
    },
    inject: ['theme'],
    computed: {
        memo() {
            return this.$store.state.moduleCourse.memos[this.memoId];
        },
        games() {
            if (this.noGames) {
                return null;
            } else {
                return {
                    gameA: this.memo.gameA || {},
                    gameB: this.memo.gameB || {}
                };
            }
        }
        // cardTheme () {
        //   const themeColor = this.theme.color
        //   return {
        //     fantasyBackground: Color(themeColor).mix(Color('black'), 0.8),
        //     fantasyBackgroundLighter1: Color(themeColor).mix(Color('black'), 0.8).mix(Color('white'), 0.1),
        //     fantasyBackgroundLighter2: Color(themeColor).mix(Color('black'), 0.8).mix(Color('white'), 0.3)
        //   }
        // }
    },
    created() {
        if (this.$route.query.initialFantasyMode) {
            this.initialFantasyMode = this.$route.query.initialFantasyMode;
            this.initialMnemonicIndex = this.$route.query.initialMnemonicIndex;
            this.$router.replace({
                name: this.$route.name,
                params: this.$route.params
            });
        } else if (this.startWithStoryMode) {
            this.initialMnemonicIndex = 0;
            this.initialFantasyMode = 'STORY';
        } else {
            this.initialMnemonicIndex = -1;
            this.initialFantasyMode = 'IMAGE';
        }
    },
    methods: {
        gotoGame(gameId) {
            this.$router.push({
                name: 'GameView',
                params: {
                    activityName: this.activityName,
                    gameId
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoCard {
    color: black;

    box-shadow: 0px 0px 1em rgba(0, 0, 0, 0.3);
    overflow: hidden;
    border-radius: 1em;
}

@include media('>=mobile') {
    .MemoCard {
        max-height: calc(100% - 4em);
        --memo-mnemonicImageSize: 5em;
    }

    .MemoCard.MemoSmall {
        height: calc(100% - #{$topBarHeight * 2});
        max-height: 28em;
    }
}

@include media('<mobile') {
    .MemoCard.MemoSmall {
        max-height: calc(100% - #{$topBarHeight * 2});
        height: 150vw;
    }
}
</style>
