<template>
  <div class="MnemonicTip">
    <div
      class="imageSection --global-clickable"
      @click="tryChangeTipImage()">
      <MnemonicImage
        v-if="imageIndex === 0"
        class="image"
        :image="mnemonic.tip.imageUrl || mnemonic.imageUrl"
        :focus="true" />
      <MnemonicImage
        v-if="mnemonic.tip.numbershape && imageIndex === 1"
        class="image"
        :image="mnemonic.tip.numbershape"
        :focus="true" />
      <div
        v-if="mnemonic.tip.numbershape"
        class="imageSectionDots">
        <div :class="{ 'is-active': imageIndex === 0 }" />
        <div :class="{ 'is-active': imageIndex === 1 }" />
      </div>
    </div>
    <div class="text">
      <span>{{ mnemonic.tip.text }}</span>
    </div>
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage },
    props: {
        mnemonic: Object
    },
    data() {
        return {
            imageIndex: 0
        };
    },
    mounted() {
        this.imageIndex = 0;
        clearTimeout(this.timeoutIdForTipImage);
        if (this.mnemonic.tip.numbershape) {
            this.timeoutIdForTipImage = setTimeout(() => {
                this.imageIndex = 1;
            }, 3000);
        }
    },
    beforeDestroy() {
        clearTimeout(this.timeoutIdForTipImage);
    },
    methods: {
        tryChangeTipImage() {
            if (this.imageIndex === 1) {
                this.imageIndex = 0;
            } else if (this.mnemonic.tip.numbershape) {
                this.imageIndex = 1;
            }
            clearTimeout(this.timeoutIdForTipImage);
        }
    }
};
</script>

<style lang="scss" scoped>
.MnemonicTip {
    padding: 1em;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    border-radius: 4px;
    // max-width: 30em;
    // margin: 0 1em;
    // flex-shrink: 0;
    //
    // box-shadow: 0 0 1em rgba(black, 0.3);
}
.imageSection {
    // $size: 8em;
    // width: $size;
    // height: $size;
    // border: 1px solid black;
    flex-shrink: 0;
    margin-right: 1em;
}
.image {
    $size: 8em;
    width: $size;
    height: $size;
    border: 1px solid mix(black, white, 20%);
    flex-shrink: 0;
    // margin-right: 1em;
    border-radius: 4px;
    & > * {
        border-radius: 4px;
    }
}
.text {
    flex: 1;
    line-height: 1.2em;
    color: rgba(black, 0.7);
    font-weight: normal;

    span {
        font-size: 90%;
    }
}
.imageSectionDots {
    display: flex;
    justify-content: center;
    margin-top: 0.3em;
    & > * {
        $size: 7px;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: black;
        margin: 5px;
        background-color: mix(black, white, 20%);
        &.is-active {
            background-color: black;
        }
    }
}
</style>
