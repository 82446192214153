<template>
  <g
    v-if="typeof number === 'number'"
    :class="{ selected, context }"
    class="PeriodicTableCell">
    <rect
      class="box"
      :x="x"
      :y="y"
      :width="boxSize"
      :height="boxSize" />
    <text
      class="abbriviation"
      :x="x + boxSize / 2"
      :y="y + boxSize / 1.5"
      text-anchor="middle">
      {{ abbr }}
    </text>
  </g>
  <text
    v-else-if="number"
    class="marks"
    :x="x + boxSize / 2"
    :y="y + boxSize / 1.2"
    text-anchor="middle">
    {{ number }}
  </text>
</template>

<script>
const abbriviations = [
    'H',
    'He',
    'Li',
    'Be',
    'B',
    'C',
    'N',
    'O',
    'F',
    'Ne',
    'Na',
    'Mg',
    'Al',
    'Si',
    'P',
    'Si',
    'Cl',
    'Ar',
    'K',
    'Ca',
    'Sc',
    'Ti',
    'V',
    'Cr',
    'Mn',
    'Fe',
    'Co',
    'Ni',
    'Cu',
    'Zn',
    'Ga',
    'Ge',
    'As',
    'Se',
    'Br',
    'Kr',
    'Rb',
    'Sr',
    'Y',
    'Zr',
    'Nb',
    'Mo',
    'Tc',
    'Ru',
    'Rh',
    'Pd',
    'Ag',
    'Cd',
    'In',
    'Sn',
    'Sb',
    'Te',
    'I',
    'Xe',
    'Cs',
    'Ba',
    'La',
    'Ce',
    'Pr',
    'Nd',
    'Pm',
    'Sm',
    'Eu',
    'Gd',
    'Tb',
    'Dy',
    'Ho',
    'Er',
    'Tm',
    'Yb',
    'Lu',
    'Hf',
    'Ta',
    'W',
    'Re',
    'Os',
    'Ir',
    'Pt',
    'Au',
    'Hg',
    'Tl',
    'Pb',
    'Bi',
    'Po',
    'At',
    'Rn',
    'Fr',
    'Ra',
    'Ac',
    'Th',
    'Pa',
    'U',
    'Np',
    'Pu',
    'Am',
    'Cm',
    'Bk',
    'Cf',
    'Es',
    'Fm',
    'Md',
    'No',
    'Lr',
    'Rf',
    'Db',
    'Sg',
    'Bh',
    'Hs',
    'Mt',
    'Ds',
    'Rg',
    'Cn',
    'Nh',
    'Fl',
    'Mc',
    'Lv',
    'Ts',
    'Og'
];

export default {
    props: {
        number: [Number, String],
        boxSize: Number,
        x: Number,
        y: Number,
        context: [Boolean, Object],
        selected: Boolean,
    },
    created() {
        if (typeof this.number === 'number') {
            this.abbr = abbriviations[this.number - 1];
        }
    }
};
</script>

<style lang="scss" scoped>
.PeriodicTableCell {
    &.context {
        .box {
            fill: mix(black, white, 70%);
        }
    }
    &.selected {
        .box {
            fill: yellow;
        }

        .abbriviation,
        .number {
            fill: black;
        }
    }
}
.box {
    fill: mix(white, black, 80%);
}
.abbriviation {
    font-size: 50px;
    fill: white;
}
.number {
    font-size: 30px;
    fill: white;
}
.marks {
    font-size: 60px;
    fill: gray;
}
</style>
