<template>
  <div class="FactChemistryElement">
    <div
      ref="scrollContainer"
      class="VerticalScrollContainer">
      <div
        ref="content"
        class="content">
        <div class="contentTopPadding" />

        <div class="layoutBox">
          <div class="elementNumberBox">
            <div class="elementName">
              {{ options.name }}
            </div>
            <div class="elementAbbr">
              {{ options.abbr }}
            </div>
            <div class="elementNumber">
              {{ options.number }}
            </div>
          </div>
          <TextWithHighlight
            class="text"
            :text="text"
            :range="textHighlight" />
        </div>
        <div class="contentBottomPadding" />
      </div>
    </div>
  </div>
</template>

<script>
import scrollIntoView from './scrollIntoView';
import TextWithHighlight from './TextWithHighlight';

export default {
    components: { TextWithHighlight },
    props: {
        text: {
            type: String,
            required: true
        },
        options: {
            type: Object
        },
        // image: {
        //   type: String,
        //   default: null
        // },
        mnemonics: {
            type: Array,
            required: true
        },
        mnemonicIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        currentMnemonic() {
            return this.mnemonics[this.mnemonicIndex] || null;
        },
        textHighlight() {
            if (!this.currentMnemonic) {
                return null;
            }
            // NOTE: We only have one marking per mnemonic:
            let ref = this.currentMnemonic.factRefs[0];
            if (!ref) {
                console.warn('missing ref for fantasy', this.currentMnemonic); // eslint-disable-line no-console
                return null;
            }
            return {
                start: ref.offset,
                length: ref.length
            };
        }
    },
    methods: {
        // Public method

        getLayoutHint() {
            const contentHeight = this.$refs.content.clientHeight;
            return {
                reservedHeight: contentHeight,
                requestedHeight: contentHeight
            };
        },

        updateScroll() {
            scrollIntoView(this.$el.querySelector('.text .mark'), this.$refs.scrollContainer);
        }
    }
};
</script>

<style lang="scss" scoped>
@import './_variables.scss';
.FactChemistryElement {
    position: relative;
    background-color: white;
    overflow: hidden;
}
.VerticalScrollContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 $memo-sidePadding;
    padding: 0 var(--memo-sidePadding, $memo-sidePadding);
    overflow-y: auto;
}
.contentTopPadding,
.contentBottomPadding {
    height: 1em;
}

.content {
    // border: 1px solid red;
}
.layoutBox {
    // border: 1px solid green;
    display: flex;
}
.elementNumberBox {
    $size: 6em;
    min-width: $size;
    height: $size;
    // border: 1px solid blue;
    margin-right: 1em;
    background-color: #9e9e9e;
    color: white;
    padding: 0 0.5em;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}
.elementName {
    text-transform: capitalize;
    word-break: break-word;
}
.elementNumber {
    font-weight: 600;
}
.elementAbbr {
    font-weight: 600;
    font-size: 130%;
}
.text {
    flex: 1;
    line-height: 1.5em;

    /deep/ span.topic {
        display: contents; // NOTE: Needed in Chrome to counteract bug where a nested span does not display its outline
        font-weight: bold;
    }
    /deep/ span.mark {
        background-color: $memo-factHighlightColor;
        background-color: var(--memo-factHighlightColor, $memo-factHighlightColor);
        outline: 1px solid $memo-factHighlightColor;
        outline: 1px solid var(--memo-factHighlightColor, $memo-factHighlightColor);
        color: black;
    }
}

.image {
    $size: 8em;
    width: $size;
    height: $size;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 1.5em;
}
</style>
