<template>
  <QuizResult
    :completed="done"
    :title="$t('title')"
    :subtitle="$t('subtitle')"
    :score="score"
    :buttons="buttons"
    icon="game-icon"
    class="MemoGamesCard"
    @action="gotoGame()" />
</template>

<translations>
  # title: 'Ace the memo games'
  # title_no: 'Spill hjernen til topp hukommelse'
  # subtitle: 'Keywords to images & images to stories'
  # subtitle_no: 'Nøkkelord til bilder & bilder til historier'

  title: 'Train your memory'
  title_no: 'Tren hukommelsen'
  subtitle: 'Remember the images & Stories?'
  subtitle_no: 'Husker du bildene & historiene?'

  playFirstTime: 'Play game'
  playFirstTime_no: 'Spill'
  playAfterFail: 'Try again'
  playAfterFail_no: 'Spill igjen'
  playAfterDone: 'Play again'
  playAfterDone_no: 'Spill igjen'

  continueWithQuiz: 'Continue with quiz'
  continueWithQuiz_no: 'Fortsett med quiz'
</translations>

<script>
import quizHelper from '@/modules/course/quiz-helper';
import QuizResult from '@/modules/course/components/QuizResult';
import extractGameStates from '@/modules/course/extractGameStates';

export default {
    components: { QuizResult },
    props: {
        stepId: {
            type: String
        },
        activityName: {
            type: String
        }
    },
    data() {
        return {
            sessionResult: null,
            score: 0,
            done: false,
            buttons: null
        };
    },
    created() {
        this.sessionResult = quizHelper.lastQuizSessionResult || null;
        delete quizHelper.lastQuizSessionResult;
        const step = this.$store.state.moduleCourse.steps[this.stepId];
        const activity = step[this.activityName];
        const memos = activity.memoIds.map(memoId => this.$store.state.moduleCourse.memos[memoId]);
        this.gameStates = extractGameStates(memos);

        if (this.sessionResult) {
            this.score = this.sessionResult.score;
        }
        this.done = this.gameStates.gameA && this.gameStates.gameB;
        this.buttons = [];
        if (this.done) {
            // if (this.activityName === 'memorize2' && step.quiz2) {
            //   this.buttons.push({
            //     label: this.$t('continueWithQuiz'),
            //     action: 'gotoQuiz'
            //   })
            // }
            this.buttons.push({
                label: this.$t('playAfterDone'),
                action: 'gotoGame'
            });
        } else if (this.sessionResult) {
            this.buttons.push({
                label: this.$t('playAfterFail'),
                action: 'gotoGame'
            });
        } else {
            this.buttons.push({
                label: this.$t('playFirstTime'),
                action: 'gotoGame'
            });
        }
    },
    methods: {
        onAction(action) {
            console.log('action', action);
            this[action]();
        },
        gotoQuiz() {
            this.$router.push({
                name: 'Course-ActivityTransition',
                params: {
                    activityName: 'quiz2'
                }
            });
        },
        gotoGame() {
            this.$router.push({
                name: 'GameView',
                params: {
                    activityName: this.activityName
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoGamesCard {
}
</style>
