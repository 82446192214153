<template>
  <div
    v-if="measure"
    class="FantasyMnemonicDetail">
    <div class="mnemonicImage" />
    <div class="text">
      <span class="code">Dummy</span>
    </div>
  </div>
  <div
    v-else
    class="FantasyMnemonicDetail"
    :class="{ 'is-showing-tip': showingTip, 'has-tip': mnemonic.tip }">
    <MnemonicImage
      class="mnemonicImage"
      :image="mnemonic.imageUrl || mnemonic.numbershape"
      :focus="true" />

    <div
      v-if="showingTip"
      class="mnemonicTipContainer">
      <MnemonicTip
        xv-if="showingTip"
        class="mnemonicTip"
        :mnemonic="mnemonic"
        @click.native.stop="" />
    </div>
    <div class="text">
      <span
        v-if="mnemonic.tip"
        class="tipIndicator --global-clickable"
        @click.stop="showTip()">
        <span class="tipIndicatorIcon">i</span>
        <span class="tipIndicatorFunnel" />
      </span>
      <span class="code">{{ code }}</span>
    </div>
  </div>
</template>

<script>
import MnemonicImage from '@/components/MnemonicImage';

import MnemonicTip from './MnemonicTip';

export default {
    components: { MnemonicImage, MnemonicTip },
    props: {
        mnemonic: {
            type: Object
        },
        fantasyText: {
            type: String
        },
        measure: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showingTip: false
        };
    },
    computed: {
        code() {
            let code = '';
            let ref = this.mnemonic.refs[0];
            if (ref) {
                code = this.fantasyText.substr(ref.offset, ref.length);
            }
            return code;
        }
    },
    methods: {
        showTip() {
            this.showingTip = !this.showingTip;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './_variables.scss';

$funnelHeight: 0.8em;

.FantasyMnemonicDetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    justify-content: flex-start;

    padding: 0 $memo-sidePadding;
    padding: 0 var(--memo-sidePadding, $memo-sidePadding);
    padding-bottom: 1em;
    padding-top: 1.5em;

    &.is-showing-tip.has-tip {
        .mnemonicImage {
            visibility: hidden;
        }
        .tipIndicatorFunnel {
            border-top: $funnelHeight solid white;
            transition: border-top 0.3s;
        }
    }
    &:not(.is-showing-tip) {
        .mnemonicTip {
            display: none;
        }
    }
}

.mnemonicImage {
    $size: 12em;
    flex-shrink: 0;
    width: $size;
    height: $size;
    border-radius: 4px;
    box-shadow: 0 0 1em rgba(black, 0.3);

    & > * {
        border-radius: 4px;
    }
}

.text {
    position: relative;
    margin-top: 1em;
    text-align: center;
    padding: 0 3em;
    line-height: 1.5em;
    max-width: 32em;
}

.code {
    color: $memo-fantasyHighlightColor;
    color: var(--memo-fantasyHighlightColor, $memo-fantasyHighlightColor);

    font-weight: bold;
    word-break: break-word;
}

.mnemonicTipContainer {
    position: relative;
    width: 100%; //calc(100% - 2em);
    // margin: 0 3em;
    max-width: 30em;
}
.mnemonicTip {
    position: absolute;
    bottom: 0;

    width: 100%;
    // max-width: 30em;
    // margin: 0 1em;
    // flex-shrink: 0;

    box-shadow: 0 0 1em rgba(black, 0.3);
}

.tipIndicator {
    $size: 1.4em;
    position: relative;
    display: inline-flex;
    width: $size;
    height: $size;
    // background-color: rgba($memo-fantasyHighlightColor, 0.8);
    background-color: var(--memo-fantasyHighlightColor, $memo-fantasyHighlightColor);

    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: black;
    font-style: italic;
    margin-left: 0.2em;
    font-weight: normal;

    float: right;
    margin-left: 0.5em;

    &::after {
        // Click area
        $dist: -0.5em;
        content: '';
        position: absolute;
        top: $dist;
        right: $dist;
        bottom: $dist;
        left: $dist;
    }
}
.tipIndicatorIcon {
    font-size: 80%;
}
.tipIndicatorFunnel {
    $width: 1.8em;
    display: block;
    position: absolute;
    top: -1em;
    left: -0.2em;

    width: 0;
    height: 0;
    border-left: #{$width / 2} solid transparent;
    border-right: #{$width / 2} solid transparent;
    border-top: 0 solid white;
}
</style>
