<template>
  <div
    class="QuizResult"
    :class="{ success: score === 1 }">
    <div
      v-if="completed"
      class="completedTag">
      {{ $t('completed') }}
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="subtitle">
      {{ subtitle }}
    </div>

    <div class="spacer1" />

    <div
      v-if="image"
      class="image"
      :style="{ backgroundImage: `url(${image})` }" />
    <div
      v-else
      class="iconAndProgress">
      <svgicon :icon="icon" />

      <CircularProgress
        class="progressIndicator"
        :progress="theProgress"
        color="rgba(255, 255, 255, 0.9)"
        track-color="rgba(0, 0, 0, 0.05)"
        :stroke-width="3" />
    </div>
    <div class="spacer2" />

    <div
      v-if="text"
      class="text">
      {{ text }}
    </div>
    <div
      v-if="buttons && text"
      class="spacer3" />
    <div
      v-if="buttons"
      class="buttons">
      <div
        v-for="(button, i) of buttons"
        :key="i"
        class="button --global-clickable"
        :class="{ 'button--secondary': i > 0 }"
        @click="$emit('action', button.action)">
        {{ button.label }}
      </div>
    </div>
  </div>
</template>

<translations>
  completed: 'Completed'
  completed_no: 'Fullført'
</translations>

<script>
import CircularProgress from '@/components/CircularProgress';

export default {
    components: {
        CircularProgress
    },
    props: {
        completed: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        },
        subtitle: {
            type: String
        },
        text: {
            type: String
        },
        score: {
            type: Number,
            default: 0
        },
        icon: {
            type: String,
            default: 'activities/quiz'
        },
        image: {
            type: String
        },
        showCheckmark: {
            type: Boolean,
            default: false
        },
        buttons: {
            type: Array
        }
    },
    data() {
        return {
            theProgress: 0,
            showingCheckmarkNow: false
        };
    },
    watch: {
        score: {
            handler(val) {
                if (val) {
                    setTimeout(() => {
                        this.theProgress = val;
                    }, 1000);
                } else {
                    this.theProgress = 0;
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (this.showCheckmark) {
            setTimeout(() => {
                this.showingCheckmarkNow = true;
            }, 2000);
        }
    }
};
</script>

<style lang="scss" scoped>
.QuizResult {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 20em;

    &.success {
        .iconAndProgress {
            animation: success 0.5s;
            animation-delay: 2s;

            @keyframes success {
                0% {
                    transform: scale(1);
                }
                50% {
                    transform: scale(1.1);
                }
                100% {
                    transform: scale(1);
                }
            }
        }
    }
}
.completedTag {
    text-transform: uppercase;
    font-size: 80%;
    color: rgba(black, 0.6);
    margin-bottom: 0.5em;
    background-color: rgba(white, 0.5);
    padding: 0.3em 0.7em;
    border-radius: 1em;
}
.title {
    font-size: 200%;
    color: rgba(white, 0.9);
    text-align: center;
    font-weight: 600;
}
.subtitle {
    line-height: 1.5em;
    text-align: center;
    color: rgba(white, 0.7);
    margin-top: 0.3em;
}
.text {
    line-height: 1.5em;
    text-align: center;
    color: rgba(white, 0.7);
    // margin-top: 1em;
}

.iconAndProgress {
    $size: 10em;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: $completionColor;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    .svg-icon {
        $size: 4em;
        width: $size;
        height: $size;
        fill: rgba(white, 0.9);
    }
}

.progressIndicator {
    $offset: 0.3em;
    position: absolute;
    top: $offset;
    right: $offset;
    bottom: $offset;
    left: $offset;

    transition: opacity 0.5s, transform 0.5s;
}

.image {
    $size: 10em;
    width: $size;
    height: $size;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;
}

.button {
    padding: 0.7em 1em;
    background-color: white;
    border-radius: 2em;
    color: black;
    text-align: center;
}
.button--secondary {
    background-color: transparent;
    color: rgba(white, 0.7);
    margin-top: 0.5em;
}

.spacer1 {
    height: 2em;
}
.spacer2 {
    height: 2em;
}
.spacer3 {
    height: 1em;
}
</style>
