<template>
  <div class="HideByWrap">
    <div class="helper" />
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.HideByWrap {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: center;

    width: 0;
    flex: 1;
}

.helper {
    height: 100%;
}
</style>
