<template>
  <div class="TextWithHighlight">
    <div
      class="text"
      :class="[`theme--${theme}`]"
      v-html="$options.filters.markTextRange(text, range)" />
  </div>
</template>

<script>
function markTextRange(text, range) {
    if (!range) {
        return text;
    } else {
        return [
            text.substr(0, range.start),
            '<span class="mark">',
            text.substr(range.start, range.length),
            '</span>',
            text.substr(range.start + range.length)
        ].join('');
    }
}
export default {
    filters: {
        markTextRange
    },
    props: {
        text: {
            type: String
        },
        range: {
            type: Object
        },
        theme: {
            type: String,
            default: '' // 'light'
        }
    }
};
</script>
