<template>
  <div
    class="ToggleButton --global-clickable"
    @click="toggle()">
    <div class="frame">
      <div
        class="indicator"
        :data-index="index" />
      <div
        v-for="option of options"
        :key="option.value"
        class="Option"
        :class="{ 'is-active': option.value === value }">
        <svgicon
          class="svg-icon"
          :icon="option.icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array
        },
        value: {
            type: String
        }
    },
    computed: {
        index() {
            return this.options.findIndex(option => option.value === this.value);
        }
    },
    methods: {
        toggle() {
            this.$emit('input', this.options[(this.index + 1) % this.options.length].value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';

$optionSize: 1.7em;
$framePadding: 4px;
$optionGap: 0.2em;

.ToggleButton {
    display: flex;
}

.frame {
    position: relative;
    display: flex;
    background-color: $memo-fantasyBackgroundLighter1;
    background-color: var(--memo-fantasyBackgroundLighter1, $memo-fantasyBackgroundLighter1);
    border-radius: 2em;
    padding: $framePadding;
}

.indicator {
    position: absolute;
    width: $optionSize;
    height: $optionSize;
    border-radius: 50%;
    background-color: $memo-fantasyBackgroundLighter2;
    background-color: var(--memo-fantasyBackgroundLighter2, $memo-fantasyBackgroundLighter2);
    margin: $framePadding;

    top: 0;
    left: 0;

    transition: left 0.3s;
}

.indicator[data-index='0'] {
    left: 0;
}
.indicator[data-index='1'] {
    left: $optionSize + $optionGap;
}

.Option {
    position: relative;
    width: $optionSize;
    height: $optionSize;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.5;

    &:not(:last-child) {
        margin-right: $optionGap;
    }

    .svg-icon {
        $size: 50%;
        width: $size;
        height: $size;
        fill: white;
    }

    &.is-active {
        opacity: 1;
    }
}
</style>
