<template>
  <SimpleSlides
    class="MemoOnboardingPopup"
    :button-label="$t(`getStarted`)"
    @update:index="activeSlideIndex = $event"
    @done="$emit('signal', 'done')">
    <template slot="slides">
      <div
        v-for="i of 3"
        :key="i"
        class="Slide">
        <div
          class="Slide__image"
          :style="{ backgroundImage: `url(${getSlideImage(i)})` }" />
        <div class="Slide__title">
          {{ $t(`slide${i}.title`) }}
        </div>
        <div class="Slide__text">
          {{ $t(`slide${i}.text`) }}
        </div>
      </div>
    </template>
  </SimpleSlides>
</template>

<translations>
  getStarted: 'Get Started'
  getStarted_no: 'Kom i gang'

  slide1:
    title: 'Read & remember the summary'
    title_no: 'Les & husk sammendraget'
    text: 'In two steps, use fun and science based memory techniques to remember the summary. Training your brain is a nice side effect.'
    text_no: 'På kun to steg, gjør deg klar til å bruke morsomme og vitenskapsbasert teknikker for å huske sammendraget. Du trener samtidig hjernen din.'

  slide2:
    title: '1) Link keywords to images'
    title_no: '1) Link nøkkelord til bilder'
    text: 'Our brain remembers with images, so the first step is to link the most important keywords from the summary to images'
    text_no: 'Hjernen vår husker bedre med bilder, så første steg er å koble de viktigste nøkkelordene fra sammendraget til bilder.'

  slide3:
    title: '2) Link images to a story'
    title_no: '2) Link bildene til en historie'
    text: 'To remember the right sequence of the summary, all we have to do is link the keywords together in a fun, imaginative story.'
    text_no: 'For å huske rekkefølgen i sammendraget trenger du kun å koble bildene sammen til en morsom og fantasifull historie.'
</translations>

<script>
import SimpleSlides from '@/components/SimpleSlides';
import img1 from './img1.png';
import img2 from './img2.png';
import img3 from './img3.png';

export default {
    components: { SimpleSlides },
    methods: {
        getSlideImage(slideNumber) {
            return [img1, img2, img3][slideNumber - 1];
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoOnboardingPopup {
    width: 40em;
}

.Slide {
    text-align: center;
    padding: 2em 0;
}
.Slide__image {
    $size: 14em;
    width: $size;
    height: $size;
    margin: auto;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.Slide__title {
    font-size: 130%;
    font-weight: 600;
    text-align: center;
}
.Slide__text {
    line-height: 1.5em;
    margin: auto;
    margin-top: 1em;
    max-width: 30em;
}

@include media('<=mobile') {
    .Slide__image {
        $size: 10em;
        width: $size;
        height: $size;
    }

    .Slide {
        padding: 1em 0;
    }
}
</style>
