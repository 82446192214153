<template>
  <div class="MemoSmall">
    <div
      class="factSection"
      :class="{ overlayText: factOptions.imageFill }"
      :style="factStyle">
      <div class="content">
        <!-- <div v-if="fact.imageUrl && !factOptions.imageFill" class="factImage" :style="{ backgroundImage: `url(${fact.imageUrl})`}"></div> -->
        <div
          class="factText"
          :class="{ '--global-clickable': fact.soundUrl }"
          @click="fact.soundUrl && playFactSound()">
          <span v-html="fact.text" />
        </div>
      </div>
    </div>
    <div
      class="divider"
      :class="{ visibleDivider: fantasyBgIsLight }">
      <div
        v-if="hasSounds"
        class="playButton --global-clickable"
        @click="playAll()">
        <svgicon
          class="playIcon"
          icon="play-triangle" />
      </div>
    </div>
    <div
      class="fantasySection"
      :class="{ overlayText: fantasyOptions.imageFill }"
      :style="fantasyStyle">
      <div class="content">
        <div
          class="fantasyCode"
          :class="{ '--global-clickable': fantasy.codeSoundUrl }"
          @click="fantasy.codeSoundUrl && playFantasyCodeSound()">
          <span
            :style="{ backgroundColor: fantasyStyle.textBg }"
            v-html="fantasy.code" />
        </div>

        <div
          class="fantasyText"
          :class="{ '--global-clickable': fantasy.soundUrl }"
          @click="fantasy.soundUrl && playFantasySound()">
          <span
            :style="{ backgroundColor: fantasyStyle.textBg }"
            v-html="fantasy.text" />
        </div>
        <!-- <div v-if="fantasy.imageUrl && !fantasyOptions.imageFill" class="fantasyImage" :style="{ backgroundImage: `url(${fantasy.imageUrl})`}"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import soundService from '@/services/sound-service';
import { Howl } from 'howler';
import Color from 'color';

export default {
    props: {
        fact: {
            type: Object
        },
        fantasy: {
            type: Object
        }
    },
    computed: {
        factOptions() {
            const options = this.fact.options || {};
            if (options.bg) {
                options.imageFill = true;
            }
            return options;
        },
        fantasyOptions() {
            const options = this.fantasy.options || {};
            if (options.bg) {
                options.imageFill = true;
            }
            return options;
        },
        factStyle() {
            const style = {
                backgroundColor: this.factOptions.bg || 'white'
            };
            if (this.fact.image) {
                style.backgroundImage = `url('${this.fact.image}')`;
                style.backgroundRepeat = 'no-repeat';

                // standard
                style.backgroundSize = 'auto 100%';
                style.backgroundPosition = 'center right';
            }
            if (this.factOptions.stretch) {
                style.backgroundSize = '102% 102%';
            }
            return style;
        },
        fantasyBgIsLight() {
            return this.fantasyStyle.backgroundColor.isLight();
        },
        fantasyStyle() {
            const bgColor = Color(this.fantasyOptions.bg || 'black');

            const style = {
                backgroundColor: bgColor,
                color: bgColor.isLight() ? 'black' : 'white',
                textBg: bgColor.alpha(0.8)
            };

            if (this.fantasy.imageUrl) {
                style.backgroundImage = `url('${this.fantasy.imageUrl}')`;
                style.backgroundRepeat = 'no-repeat';

                // standard
                style.backgroundSize = 'auto 100%';
                style.backgroundPosition = 'center right';
            }
            return style;
        },
        hasSounds() {
            return this.fact.soundUrl || this.fantasy.codeSoundUrl || this.fantasy.soundUrl;
        }
    },
    mounted() {
        this.$emit('summary-read');
    },
    beforeDestroy() {
        this.stopSounds();
    },
    methods: {
        stopSounds() {
            const fadeDuration = 500;
            soundService.stop(this.fact.soundUrl, fadeDuration);
            soundService.stop(this.fantasy.soundUrl, fadeDuration);
            soundService.stop(this.fantasy.codeSoundUrl, fadeDuration);
            if (this._sounds) {
                this._sounds.forEach(s => {
                    s.once('fade', () => s.stop());
                    s.fade(s.volume(), 0, fadeDuration);
                });
                delete this._sounds;
            }
        },
        playFactSound() {
            this.stopSounds();
            soundService.play(this.fact.soundUrl);
        },
        playFantasySound() {
            this.stopSounds();
            soundService.play(this.fantasy.soundUrl);
        },
        playFantasyCodeSound() {
            this.stopSounds();
            soundService.play(this.fantasy.codeSoundUrl);
        },
        playAll() {
            const soundUrls = [];
            if (this.fact.soundUrl) {
                soundUrls.push(this.fact.soundUrl);
            }
            if (this.fantasy.codeSoundUrl) {
                soundUrls.push(
                    'https://s3.eu-west-1.amazonaws.com/memomaps-data.memolife.com/tts-sounds/kan-minne-deg-om.mp3.bbb771b9-2cad-481c-bbf0-ab0962ed904c.mp3'
                );
                soundUrls.push(this.fantasy.codeSoundUrl);
            }
            if (this.fantasy.soundUrl) {
                soundUrls.push(
                    'https://s3.eu-west-1.amazonaws.com/memomaps-data.memolife.com/tts-sounds/forestill-deg.b76815df-0f8d-427d-a8c2-a8aebec98b26.mp3'
                );
                soundUrls.push(this.fantasy.soundUrl);
            }
            if (!soundUrls.length) {
                return;
            }
            this.stopSounds();
            this._sounds = soundUrls.map(url => {
                return new Howl({
                    src: [url],
                    volume: 1,
                    preload: true
                });
            });
            this._sounds.reduce((sound, nextSound) => {
                sound.once('end', function () {
                    nextSound.play();
                });
                return nextSound;
            });
            this._sounds[0].play();
        }
    }
};
</script>

<style lang="scss" scoped>
.MemoSmall {
    position: absolute;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.factSection {
    background-color: white;
    color: black;
    flex: 1;
    height: 50%;
    padding: 1em 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // &.overlayText {
    align-items: flex-start;
    .factText > span {
        background-color: rgba(white, 0.9);
    }
    // }
    // &:not(.overlayText) {
    //   text-align: center;
    // }
}
.fantasySection {
    background-color: black;
    color: white;
    height: 50%;
    flex: 1;
    padding: 1em 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // &.overlayText {
    align-items: flex-start;
    .fantasyCode > span {
        background-color: rgba(white, 0.9);
    }
    .fantasyText > span {
        background-color: rgba(white, 0.9);
    }
    // }
    // &:not(.overlayText) {
    //   text-align: center;
    // }
}
.content {
    max-width: 20em;
}
.divider {
    display: flex;
    justify-content: center;
    align-items: center;

    &.visibleDivider {
        height: 1px;
        background-color: mix(black, white, 20%);
    }
}
.factImage {
    $size: 7em;
    width: $size;
    height: $size;
    margin-bottom: 1em;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.fantasyImage {
    $size: 7em;
    width: $size;
    height: $size;
    margin-top: 1em;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.factText {
    font-size: 180%;
}
.fantasyCode {
    font-size: 140%;
}
.fantasyText {
    margin-top: 2em;
    line-height: 1.4em;
    font-size: 120%;
}

.playButton {
    $size: 2.4em;
    width: $size;
    height: $size;
    background-color: white;
    border: 1px solid rgba(black, 0.1);
    border-radius: 50%;
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.playIcon {
    $size: 1em;
    width: $size;
    height: $size;
}
</style>
