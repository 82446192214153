<template>
  <div
    v-if="measure"
    class="FantasyMnemonicImages">
    <div class="images">
      <div class="image">
        <div class="MnemonicImage" />
      </div>
    </div>
  </div>
  <HorizontalScrollContainer
    v-else
    ref="horizontalScrollContainer"
    class="FantasyMnemonicImages">
    <div class="images">
      <div
        v-for="(mnemonic, i) in mnemonics"
        :key="i"
        class="image --global-clickable"
        :class="{ selected: i === mnemonicIndex, endOfHead: i + 1 === headLength }"
        @click="$emit('update:mnemonic-index', i)">
        <MnemonicImage
          class="MnemonicImage"
          :image="mnemonic.imageUrl || mnemonic.numbershape"
          :focus="i === mnemonicIndex" />
      </div>
    </div>
  </HorizontalScrollContainer>
</template>

<script>
import HorizontalScrollContainer from '@/components/HorizontalScrollContainer';
import MnemonicImage from '@/components/MnemonicImage';

export default {
    components: { MnemonicImage, HorizontalScrollContainer },
    props: {
        mnemonics: {
            type: Array,
            required: true
        },
        mnemonicIndex: {
            type: Number,
            default: 0
        },
        headLength: {
            type: Number,
            default: 0
        },
        measure: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        mnemonicIndex: function (val) {
            this.scrollIntoView(true);
        }
    },
    mounted() {
        this.scrollIntoView(false);
    },
    methods: {
        scrollIntoView(animate) {
            if (this.mnemonicIndex !== -1) {
                let elem = this.$el.querySelector(`.image:nth-child(${this.mnemonicIndex + 1}) .MnemonicImage`);
                if (elem) {
                    this.$refs.horizontalScrollContainer.scrollElementIntoView(elem, animate);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './_variables.scss';

.FantasyMnemonicImages {
}

.images {
    display: inline-flex;

    margin-left: $memo-sidePadding;
    margin-left: var(--memo-sidePadding, $memo-sidePadding);
    margin-right: 1em;
}

.image {
    position: relative;
    padding: 2px;
    border-radius: 6px;
    border: 2px solid transparent;
    &:not(:last-child) {
        margin-right: 8px;
    }
    &.selected {
        border-color: $memo-fantasyHighlightColor;
        border-color: var(--memo-fantasyHighlightColor, $memo-fantasyHighlightColor);
    }

    &.endOfHead {
        margin-right: 2.2em;
        &::before {
            content: '';
            color: white;
            position: absolute;
            display: block;
            height: 1px;
            width: 0.7em;
            background-color: rgba(white, 0.5);
            top: 50%;
            right: -1.7em;
            pointer-events: none;
        }
    }

    // NOTE: inside .image to increase specificity
    .MnemonicImage {
        $size: $memo-mnemonicImageSize;

        width: $size;
        width: var(--memo-mnemonicImageSize, $size);
        height: $size;
        height: var(--memo-mnemonicImageSize, $size);
        border-radius: 2px;
    }
}
</style>
