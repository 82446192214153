<template>
  <div class="FantasyNavigation">
    <div class="side">
      <ToggleButton
        :value="mode"
        :options="[
          { icon: 'memo-image-2', value: 'IMAGE' },
          { icon: 'memo-story-2', value: 'STORY' }
        ]"
        @input="mode => $emit('update:mode', mode)" />
      <div class="spacer" />
    </div>

    <div class="mnemonicsNav middle">
      <div
        class="prevMnemonicButton"
        :class="{ disabled: atStart, '--global-clickable': !atStart }"
        @click="prev()">
        <svgicon
          class="svg-icon"
          icon="chevron-down" />
      </div>
      <div class="mnemonicsNavInner">
        <div class="mnemonicsNavPosition">
          {{ index === null ? 0 : index + 1 }}/{{ length }}
        </div>
      </div>
      <CircularButton
        class="nextMnemonicButton"
        :class="{ disabled: atEnd, defaultAction: indicateNext && index < length - 1 }"
        :pulse="indicateNext && index === -1"
        @click="next()">
        <svgicon
          class="svg-icon"
          icon="chevron-down" />
      </CircularButton>
    </div>

    <div class="side side--right">
      <template v-if="mode === 'STORY' && games.gameB">
        <HideByWrap class="selectGameLabel">
          {{ $t('playGameB') }}
        </HideByWrap>
        <div
          class="selectGameButton --global-clickable"
          :class="{ hasFocus: indicateNext && index === length - 1 }"
          @click="$emit('request-game', 'gameB')">
          <svgicon
            class="svg-icon"
            icon="memo-gamepad" />
        </div>
      </template>
      <template v-else-if="mode === 'IMAGE' && games.gameA">
        <HideByWrap class="selectGameLabel">
          {{ $t('playGameA') }}
        </HideByWrap>
        <div
          class="selectGameButton --global-clickable"
          :class="{ hasFocus: indicateNext && index === length - 1 }"
          @click="$emit('request-game', 'gameA')">
          <svgicon
            class="svg-icon"
            icon="memo-gamepad" />
        </div>
      </template>
    </div>
  </div>
</template>

<translations>
playGameA: 'Play Images'
playGameA_no: 'Spill bildene'
playGameB: 'Play Story'
playGameB_no: 'Spill historien'
</translations>

<script>
import CircularButton from '@/components/CircularButton';
import HideByWrap from '@/components/HideByWrap';
import ToggleButton from './ToggleButton';

export default {
    components: { ToggleButton, CircularButton, HideByWrap },
    props: {
        length: {
            type: Number,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        indicateNext: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: 'IMAGE'
        },
        games: {
            type: Object,
            required: true
        }
    },
    computed: {
        atStart() {
            return this.index === -1;
        },
        atEnd() {
            if (this.mode === 'IMAGE' && !this.games.gameA) {
                // NOTE: End of IMAGE mode (if no game), does not count as the end, because the arrow should navigate to STORY mode
                return false;
            }
            return this.index === this.length - 1;
        }
    },
    methods: {
        prev() {
            if (this.index > -1) {
                this.$emit('update:index', this.index - 1);
            }
        },
        next() {
            if (this.index < this.length - 1) {
                this.$emit('update:index', this.index + 1);
            } else if (this.mode === 'IMAGE') {
                this.$emit('update:mode', 'STORY');
                this.$emit('update:index', -1);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';

$memoBlueButtonColor: $standardButtonColor;
$footerFontSize: 100%;

.FantasyNavigation {
    position: relative;
    padding: 0 1em;
    display: flex;
    align-items: center;
    color: white;

    & > * {
        position: relative;
    }

    &.small {
        background-color: brown;
    }
}

.CircularButton.defaultAction {
    border-radius: 50%;
    background-color: $memoBlueButtonColor;
}

.selectGameLabel {
    font-weight: 300;
    color: rgba(white, 0.8);
    height: 2em;
    justify-content: flex-end;
}

.middle {
    margin: 0 1em;
}
.side {
    flex: 1;
}
.side--right {
    display: flex;
    justify-content: flex-end;
}
.spacer {
    flex: 1;
}

.selectGameLabel {
    padding-right: 1em;
    height: 2em;
}

.selectGameButton {
    background-color: $memo-fantasyBackgroundLighter2;
    background-color: var(--memo-fantasyBackgroundLighter2, $memo-fantasyBackgroundLighter2);
    border-radius: 50%;
    $size: 2em;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .svg-icon {
        $size: 1em;
        width: $size;
        height: $size;
        fill: white;
    }

    &.hasFocus {
        background-color: $memoBlueButtonColor;
    }
}

.mnemonicsNav {
    display: inline-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}
.prevMnemonicButton,
.nextMnemonicButton {
    $size: 2em;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
        opacity: 0.2;
        cursor: default;
    }

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: white;
        display: block;
    }
}
.mnemonicsNavInner {
    padding: 4px 0.5em;
    display: flex;
    align-items: center;
    border-radius: 2px;
}
.mnemonicsNavPosition {
    color: rgba(white, 0.6);
    font-size: $footerFontSize;
    padding: 0em 0.5em;
    height: 1.7em;
    line-height: 1.7em;
    border-radius: 2px;
}

.prevMnemonicButton .svg-icon {
    transform: rotate(90deg);
}
.nextMnemonicButton .svg-icon {
    transform: rotate(-90deg);
}
</style>
