<template>
  <div class="FactStandard">
    <div
      ref="scrollContainer"
      class="VerticalScrollContainer">
      <div ref="content">
        <div class="contentTopPadding" />
        <div
          v-if="image"
          class="image"
          :style="{ backgroundImage: `url(${image})` }" />
        <TextWithHighlight
          class="text"
          :text="text"
          :range="textHighlight"
          :style="textStyle" />
        <div class="contentBottomPadding" />
      </div>
    </div>
  </div>
</template>

<script>
// import ResizeObserver from 'resize-observer-polyfill'
import scrollIntoView from './scrollIntoView';
import TextWithHighlight from './TextWithHighlight';

export default {
    components: { TextWithHighlight },
    props: {
        text: {
            type: String,
            required: true
        },
        options: {
            type: Object
        },
        image: {
            type: String,
            default: null
        },
        mnemonics: {
            type: Array,
            required: true
        },
        mnemonicIndex: {
            type: Number,
            required: true
        }
    },
    computed: {
        currentMnemonic() {
            return this.mnemonics[this.mnemonicIndex] || null;
        },
        textHighlight() {
            if (!this.currentMnemonic) {
                return null;
            }
            // NOTE: We only have one marking per mnemonic:
            let ref = this.currentMnemonic.factRefs[0];
            if (!ref) {
                console.warn('missing ref for fantasy', this.currentMnemonic); // eslint-disable-line no-console
                return null;
            }
            return {
                start: ref.offset,
                length: ref.length
            };
        },
        textStyle() {
            if (this.options && this.options.fontSize) {
                return {
                    fontSize: this.options.fontSize
                };
            } else {
                return null;
            }
        }
    },
    // created () {
    //   this.observer = new ResizeObserver(this.handleContentResize)
    //   this.active = false
    // },
    // mounted () {
    //   this.observer.observe(this.$refs.content)
    //   // this.emitLayoutHint()
    //   this.active = true
    // },
    // activated () {
    //   if (!this.active) {
    //     this.observer.observe(this.$refs.content)
    //     // this.emitLayoutHint()
    //   }
    // },
    // deactivated () {
    //   this.observer.unobserve(this.$refs.content)
    //   this.active = false
    // },
    // beforeDestroy () {
    //   this.observer.unobserve(this.$refs.content)
    // },
    methods: {
        // emitLayoutHint () {
        //   this.$emit('layout-hint', this.getLayoutHint())
        // },
        // handleContentResize (entries) {
        //   // this.emitLayoutHint()
        // },

        // Public method

        getLayoutHint() {
            const contentHeight = this.$refs.content.clientHeight;
            return {
                reservedHeight: contentHeight,
                requestedHeight: contentHeight
            };
        },

        updateScroll() {
            scrollIntoView(this.$el.querySelector('.text .mark'), this.$refs.scrollContainer);
        }
    }
};
</script>

<style lang="scss" scoped>
@import './_variables.scss';
.FactStandard {
    position: relative;
    background-color: white;
    overflow: hidden;
}
.VerticalScrollContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 $memo-sidePadding;
    padding: 0 var(--memo-sidePadding, $memo-sidePadding);
    overflow-y: auto;
}
.contentTopPadding,
.contentBottomPadding {
    height: 1em;
}
.text {
    line-height: 1.5em;

    /deep/ span.topic {
        display: contents; // NOTE: Needed in Chrome to counteract bug where a nested span does not display its outline
        font-weight: bold;
    }
    /deep/ span.mark {
        background-color: $memo-factHighlightColor;
        background-color: var(--memo-factHighlightColor, $memo-factHighlightColor);
        outline: 1px solid $memo-factHighlightColor;
        outline: 1px solid var(--memo-factHighlightColor, $memo-factHighlightColor);
        color: black;
    }
}

.image {
    $size: 8em;
    width: $size;
    height: $size;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 1.5em;
}
</style>
