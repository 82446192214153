<template>
  <div
    class="CardStandard"
    :style="[style, { height: selfHeight }]">
    <component
      :is="factComponent"
      ref="fact"
      v-bind="fact"
      :mnemonics="fantasy.mnemonics"
      :mnemonic-index.sync="mnemonicIndex"
      class="factComponent"
      @click.native="fantasyCollapsed = !fantasyCollapsed" />

    <FantasyStandard
      ref="fantasy"
      v-bind="fantasy"
      :style="{ xheight: fantasyHeight }"
      :mnemonic-index.sync="mnemonicIndex"
      :fantasy-mode.sync="fantasyMode"
      @layout-changed="updateLayout()" />

    <div
      ref="footer"
      class="footer">
      <FantasyNavigation
        v-show="!fantasyCollapsed"
        ref="nav"
        :length="fantasy.mnemonics.length"
        :index="mnemonicIndex"
        :indicate-next="indicateNext"
        :mode="fantasyMode"
        :games="games || {}"
        @update:index="index => (mnemonicIndex = index)"
        @update:mode="mode => (fantasyMode = mode)"
        @request-game="gameId => $emit('request-game', gameId)" />

      <div
        v-show="fantasyCollapsed"
        class="showFantasy --global-clickable"
        @click="fantasyCollapsed = false">
        {{ $t('showFantasy') }}
      </div>
    </div>
  </div>
</template>

<translations>
  showFantasy: 'Show Fantasy'
  showFantasy_no: 'Vis fantasi'
</translations>

<script>
import { EventBus } from '@/event-bus';

import FactStandard from './FactStandard';
import FantasyStandard from './FantasyStandard';
import FactChemistryGroup from './FactChemistryGroup';
import FactChemistryElement from './FactChemistryElement';
import FantasyNavigation from './FantasyNavigation';

import { TimelineLite } from 'gsap';

const factComponentMap = {
    FactStandard,
    FactChemistryGroup,
    FactChemistryElement
};

export default {
    components: { FantasyStandard, FantasyNavigation },
    props: {
        fact: {
            type: Object,
            default: () => {}
        },
        fantasy: {
            type: Object,
            default: () => {}
        },
        games: {
            type: Object,
            default: () => {}
        },
        initialMnemonicIndex: {
            type: Number,
            default: -1
        },
        initialFantasyMode: {
            type: String,
            default: 'IMAGE'
        },
        indicateNext: {
            type: Boolean,
            default: false
        },
        summaryDone: {
            type: Boolean,
            default: false
        },
        theme: {
            type: Object,
            default: () => {}
        },
        allowGrowFantasy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mnemonicIndex:
                this.initialMnemonicIndex === -2 ? this.fantasy.mnemonics.length - 1 : this.initialMnemonicIndex,
            fantasyMode: this.initialFantasyMode,
            fantasyCollapsed: false,

            animate: false,

            selfHeight: 0,
            fantasyHeight: 0
        };
    },
    computed: {
        style() {
            const s = {};
            if (this.theme.fantasyBackground) {
                s['--memo-fantasyBackground'] = this.theme.fantasyBackground;
            }
            if (this.theme.fantasyBackgroundLighter1) {
                s['--memo-fantasyBackgroundLighter1'] = this.theme.fantasyBackgroundLighter1;
            }
            if (this.theme.fantasyBackgroundLighter2) {
                s['--memo-fantasyBackgroundLighter2'] = this.theme.fantasyBackgroundLighter2;
            }
            if (this.theme.fantasyBackgroundLighter) {
                s['--memo-fantasyBackground'] = this.theme.fantasyBackground;
            }
            if (this.theme.sidePadding) {
                s['--memo-sidePadding'] = this.theme.sidePadding;
            }
            if (this.theme.fantasyHighlightColor) {
                s['--memo-fantasyHighlightColor'] = this.theme.fantasyHighlightColor;
            }
            if (this.theme.mnemonicImageSize) {
                s['--memo-mnemonicImageSize'] = this.theme.mnemonicImageSize;
            }
            if (this.theme.factHighlightColor) {
                s['--memo-factHighlightColor'] = this.theme.factHighlightColor;
            }
            return s;
        },
        factComponent() {
            const componentName = (this.fact.options && this.fact.options.component) || 'FactStandard';
            return factComponentMap[componentName];
        }
    },
    watch: {
        async mnemonicIndex(val) {
            if (!this.summaryDone) {
                this.$emit('summary-read');
            }
            EventBus.$emit('mnemonicIndexChanged', {
                index: val,
                isLast: this.fantasyMode === 'STORY' && val === this.fantasy.mnemonics.length - 1
            });
            await this.$nextTick();
            this.updateLayout();
        },
        async fantasyMode() {
            await this.$nextTick();
            this.updateLayout();
        },
        fantasyCollapsed() {
            this.updateLayout();
        }
    },
    mounted() {
        EventBus.$emit('mnemonicIndexChanged', {
            index: this.mnemonicIndex,
            isLast: this.fantasyMode === 'STORY' && this.mnemonicIndex === this.fantasy.mnemonics.length - 1
        });

        this.updateLayout();
    },
    methods: {
        getMinMaxHeight() {
            const s = window.getComputedStyle(this.$el);

            const measureMax = document.createElement('div');
            measureMax.style.position = 'absolute';
            measureMax.style.maxHeight = s.maxHeight;
            measureMax.style.height = '10000px';
            measureMax.style.borderLeft = '2px solid red';
            this.$el.parentElement.appendChild(measureMax);
            const maxHeight = measureMax.clientHeight;
            measureMax.remove();

            const measureMin = document.createElement('div');
            measureMin.style.position = 'absolute';
            measureMin.style.minHeight = s.minHeight;
            measureMin.style.height = '0px';
            this.$el.parentElement.appendChild(measureMin);
            const minHeight = measureMin.clientHeight;
            measureMin.remove();

            return {
                minHeight,
                maxHeight
            };
        },
        updateLayout() {
            const {
                requestedHeight: fantasyRequestedHeight,
                reservedHeight: fantasyReservedHeight
            } = this.$refs.fantasy.getLayoutHint();
            const {
                requestedHeight: factRequestedHeight,
                reservedHeight: factReservedHeight
            } = this.$refs.fact.getLayoutHint();

            const navHeight = this.$refs.footer.clientHeight;
            const totalReservedHeight = factReservedHeight + fantasyReservedHeight + navHeight;
            const { minHeight, maxHeight } = this.getMinMaxHeight();
            const prevFantasyHeight = this.fantasyHeight;
            let selfHeight = 0;
            let fantasyHeightTarget = 0;
            if (totalReservedHeight > maxHeight) {
                const remaining = maxHeight - factRequestedHeight - fantasyRequestedHeight - navHeight;
                selfHeight = maxHeight;

                if (this.allowGrowFantasy && remaining >= 0) {
                    fantasyHeightTarget = fantasyRequestedHeight + remaining / 2;
                } else {
                    const factMinHeight = 80;
                    if (
                        factRequestedHeight + remaining < factMinHeight &&
                        this.fantasyMode === 'STORY' &&
                        this.mnemonicIndex !== -1
                    ) {
                        fantasyHeightTarget = maxHeight - factMinHeight - navHeight;
                    } else {
                        fantasyHeightTarget = fantasyRequestedHeight;
                    }
                }
            } else if (totalReservedHeight < minHeight) {
                selfHeight = minHeight;
                if (this.allowGrowFantasy) {
                    const remaining = minHeight - factRequestedHeight - fantasyRequestedHeight - navHeight;
                    fantasyHeightTarget = fantasyRequestedHeight + remaining / 2;
                } else {
                    fantasyHeightTarget = fantasyRequestedHeight;
                }
            } else {
                selfHeight = totalReservedHeight;
                if (this.allowGrowFantasy) {
                    const remaining = totalReservedHeight - factRequestedHeight - fantasyRequestedHeight - navHeight;
                    fantasyHeightTarget = fantasyRequestedHeight + remaining / 2;
                } else {
                    fantasyHeightTarget = fantasyRequestedHeight;
                }
            }

            if (this.fantasyCollapsed) {
                fantasyHeightTarget = 0;
            }

            this.selfHeight = selfHeight + 'px';
            this.$refs.fantasy.$el.style.height = fantasyHeightTarget + 'px';
            this.$refs.fantasy.$el.getBoundingClientRect(); // Force update
            this.$refs.fact.updateScroll && this.$refs.fact.updateScroll();
            this.$refs.fantasy.updateScroll && this.$refs.fantasy.updateScroll();

            if (Math.abs(prevFantasyHeight - fantasyHeightTarget) > 2) {
                if (this.animate) {
                    const tl = new TimelineLite({});
                    tl.fromTo(
                        this.$refs.fantasy.$el,
                        0.3,
                        {
                            height: prevFantasyHeight + 'px'
                        },
                        {
                            height: fantasyHeightTarget + 'px'
                        },
                        0
                    );
                } else {
                    this.animate = true;
                }
                this.fantasyHeight = fantasyHeightTarget;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import './variables.scss';

.CardStandard {
    display: flex;
    flex-direction: column;
}

.FantasyStandard {
    overflow: hidden;
    height: 100%;
}

.factComponent {
    flex: 1;
}

.footer {
    flex-shrink: 0;
    height: 3.7em;
    border-top: 1px solid rgba(white, 0.05);

    background-color: $memo-fantasyBackground;
    background-color: var(--memo-fantasyBackground, $memo-fantasyBackground);
}
.FantasyNavigation {
    height: 100%;
}

.showFantasy {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
</style>
