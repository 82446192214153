<template>
  <div class="HorizontalScrollContainer">
    <slot />
  </div>
</template>

<script>
import Hammer from 'hammerjs';
import { TweenLite } from 'gsap';

export default {
    created() {
        this.scrollX = 0;
    },
    mounted() {
        // return
        this.hammer = new Hammer.Manager(this.$el, {
            recognizers: [
                [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
                [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }]
            ]
        });
        this.hammer.on('swipe', this.handleSwipe);
        this.hammer.on('panstart', this.handlePanStart);
        this.hammer.on('panmove', this.handlePanMove);
        this.hammer.on('panend', this.handlePanEnd);
    },
    beforeDestroy() {
        this.hammer.destroy();
    },
    methods: {
        scrollElementIntoView(element, animate = true) {
            const containerRect = this.$el.getBoundingClientRect();
            const rect = element.getBoundingClientRect();
            const extraMargin = element.clientWidth * 0.7;
            let scrollLeftTargetValue = 0;
            scrollLeftTargetValue = Math.max(
                0,
                Math.min(
                    this.$el.scrollWidth - this.$el.clientWidth,
                    rect.right - containerRect.right + extraMargin + this.$el.scrollLeft
                )
            );

            if (animate) {
                TweenLite.to(this.$el, 0.5, {
                    scrollLeft: scrollLeftTargetValue
                });
            } else {
                this.$el.scrollLeft = scrollLeftTargetValue;
            }
        },
        handleSwipe(event) {},
        handlePanStart(event) {
            this.scrollX = this.$el.scrollLeft;
            event.srcEvent.stopImmediatePropagation();
            event.srcEvent.preventDefault();
            event.srcEvent.stopPropagation();
        },
        handlePanMove(event) {
            this.$el.scrollLeft = this.scrollX - event.deltaX;
        },
        handlePanEnd(event) {
            // Hack: Kind of prevents event progapation. To avoid mnemonics to trigger toggleMnemonicFocus()
            this.$el.addEventListener('click', this.preventClick, true);
            setTimeout(() => {
                if (this.$el) {
                    this.$el.removeEventListener('click', this.preventClick, true);
                }
            }, 100);
        },
        preventClick(event) {
            event.stopImmediatePropagation();
            event.stopPropagation();
        }
    }
};
</script>

<style lang="scss" scoped>
.HorizontalScrollContainer {
    overflow: hidden;
}
</style>
