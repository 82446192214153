export default function extractGameStates(memos) {
    let gameACount = 0;
    let gameBCount = 0;
    memos.forEach(memo => {
        if (memo.gameA && memo.gameA.done) {
            gameACount++;
        }
        if (memo.gameB && memo.gameB.done) {
            gameBCount++;
        }
    });
    return {
        gameA: gameACount === memos.length,
        gameB: gameBCount === memos.length
    };
}
