<template>
  <div class="PeriodicTable">
    <svg
      class="svg"
      :view-box.camel="`0 0 ${1800 - 10} ${900 + 40 - 10}`">
      <template v-for="(row, y) of grid">
        <template v-for="(number, i) of row">
          <PeriodicTableCell
            :key="`${y}-${i}`"
            :number="number"
            :box-size="boxSize"
            :x="(100 * i)"
            :y="(100 * y)"
            :context="contextMap[number]"
            :selected="number === selectedNumber" />
        </template>
      </template>

      <template v-for="(row, y) of extra">
        <template v-for="(number, i) of row">
          <PeriodicTableCell
            :key="`extra-${y}-${i}`"
            :number="number"
            :box-size="boxSize"
            :x="(100 * (i + 1))"
            :y="40 + 100 * (y + 7)"
            :context="contextMap[number]"
            :selected="number === selectedNumber" />
        </template>
      </template>
    </svg>
  </div>
</template>

<script>
import PeriodicTableCell from './PeriodicTableCell';

export default {
    components: { PeriodicTableCell },
    props: {
        contextNumbers: {
            type: Array,
            default: function () {
                return [];
            }
        },
        selectedNumber: {
            type: Number,
            default: undefined
        }
    },
    computed: {
        contextMap() {
            const m = {};
            this.contextNumbers.forEach(n => {
                m[n] = true;
            });
            return m;
        }
    },
    beforeCreate() {
        this.boxSize = 90;
        this.gapSize = 100 - this.boxSize;
        this.grid = [
            [1, '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 2],
            [3, 4, '', '', '', '', '', '', '', '', '', '', 5, 6, 7, 8, 9, 10],
            [11, 12, '', '', '', '', '', '', '', '', '', '', 13, 14, 15, 16, 17, 18],
            [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36],
            [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54],
            [55, 56, '*', 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86],
            [87, 88, '**', 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118]
        ];

        this.extra = [
            ['*', 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70],
            ['**', 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102]
        ];
    }
};
</script>

<style lang="scss" scoped>
.PeriodicTable {
}

.svg {
    width: 100%;
    display: block;
}
</style>
