<template>
  <div
    class="CircularButton"
    :class="{ '--global-clickable': !disabled, hasPulse: pulse, [`${pulseType}Anim`]: pulse }"
    @click="!disabled && $emit('click')">
    <slot />
  </div>
</template>

<script>
export default {
    props: {
        pulse: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        pulseType: {
            type: String,
            default: 'pulse'
        }
    },
    methods: {
        handleClick(e) {
            if (this.disabled) {
                return;
            }
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss" scoped>
.CircularButton {
    position: relative;

    &.pulseAnim {
        $pulseDuration: 3s;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;

            animation: pulse-animation2 $pulseDuration ease 1s infinite;

            $shadowColor: white;
            box-shadow: 0 0 0 0 rgba($shadowColor, 0.5);

            @keyframes pulse-animation2 {
                0% {
                    box-shadow: 0 0 0 0 rgba($shadowColor, 0.5);
                }
                50% {
                    box-shadow: 0 0 0 0.5em rgba($shadowColor, 0);
                }
                100% {
                    box-shadow: 0 0 0 0 rgba($shadowColor, 0);
                }
            }
        }
    }
    &.bounceAnim {
        background-color: red;

        animation: bounce 2s infinite;

        @keyframes bounce {
            0%,
            25%,
            50%,
            75%,
            100% {
                transform: translateY(0);
            }
            40% {
                transform: translateY(-20px);
            }
            60% {
                transform: translateY(-12px);
            }
        }
    }
}

// $pulseDuration: 3s;
// .CircularButton.hasPulse::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-radius: 50%;
//
//   animation: pulse-animation2 $pulseDuration ease 1s infinite;
//
//   $shadowColor: white;
//   box-shadow: 0 0 0 0 rgba($shadowColor, .5);
//
//   @keyframes pulse-animation2 {
//     0% {
//       box-shadow: 0 0 0 0 rgba($shadowColor, 0.5);
//     }
//     50% {
//       box-shadow: 0 0 0 0.5em rgba($shadowColor, 0);
//     }
//     100% {
//       box-shadow: 0 0 0 0 rgba($shadowColor, 0);
//     }
//   }
// }
</style>
